import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as style from "styles/postcard.module.scss"
import _ from "lodash"

function Badge(props) {
  return <span className="badge badge-secondary mx-1">{props.tag}</span>
}

class PostCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      badgeItems: [],
    }
  }

  componentDidMount = () => {
    if (this.props.badges) {
      let badges = this.props.badges.split(";")
      let { badgeItems } = this.state
      for (let b of badges) {
        b = _.startCase(b)
        badgeItems.push(<Badge key={b} tag={b} />)
      }
      this.setState({ badgeItems })
    }
  }

  render = () => {
    return (
      <>
        <div className={style.postCardContainer}>
          <Link to={this.props.link} className={style.postCardLink}>
            <div className={`card ${style.postCard}`}>
              <div className="card-header d-flex">
                <h5 className="card-title">{this.props.title}</h5>
              </div>
              <div className="card-body">{this.props.children}</div>
              <div className="card-footer">{this.state.badgeItems}</div>
            </div>
          </Link>
        </div>
      </>
    )
  }
}

PostCard.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  badges: PropTypes.string,
}

PostCard.defaultProps = {
  link: "https://iid.ai",
  title: "",
  badges: "",
}

export default PostCard
