import React from "react"
import { graphql } from "gatsby"
import Layout from "iid/components/layout"
import SEO from "iid/components/seo"
import PostCard from "iid/components/postcard"
import SetHtml from "iid/components/sethtml"
import _ from "lodash"

import * as style from "styles/indexTemplate.module.scss"
import { Helmet } from "react-helmet"

const NothingHereMessage = () => {
  return (
    <div className="w-100 d-flex justify-content-center pt-5">
      <h3 className="display-4">Nothing here right now, check back soon!</h3>
    </div>
  )
}

const IndexTemplate = (props) => {
  let category = props.pageContext.category
  let postCards = []
  let posts = props.data.allMarkdownRemark.nodes

  for (let i = 0; i < posts.length; i++) {
    postCards[i] = (
      <PostCard
        key={i}
        link={posts[i].fields.path}
        title={posts[i].frontmatter.title}
        badges={"data"}
      >
        <SetHtml content={posts[i].excerpt} />
      </PostCard>
    )
  }

  return (
    <Layout>
      <SEO title={`IID.ai - ${_.capitalize(category)}`} />
      <Helmet>
        <body className={style.indexBody} />
      </Helmet>
      <div className={`d-flex flex-column h-100`}>
        <div className={`${style.heading}`}>
          <h1 className="display-3 text-center text-primary">
            {_.capitalize(category)}
          </h1>
        </div>
        <div className="pb-3 bg-white">
          {postCards.length > 0 && (
            <div className={`d-flex flex-wrap ${style.cards}`}>{postCards}</div>
          )}
          {!postCards.length && <NothingHereMessage />}
        </div>
        <div className={`flex-grow-1 bg-white`}></div>
      </div>
    </Layout>
  )
}
export default IndexTemplate

export const query = graphql`
  query ($category: String) {
    allMarkdownRemark(
      filter: {
        fields: { category: { eq: $category } }
        frontmatter: { publish: { eq: true } }
      }
    ) {
      nodes {
        fields {
          category
          path
        }
        excerpt(format: PLAIN, pruneLength: 200, truncate: true)
        frontmatter {
          title
        }
      }
    }
  }
`
