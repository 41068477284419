import React from "react"

const SetHtml = (props) => {
  const { content } = props
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      className={props.className}
    />
  )
}
export default SetHtml
